

















































































































































































































































































































































































































import Vue from 'vue';
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Switches from 'vue-switches';
import Multiselect from "vue-multiselect";

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    Multiselect,
    Switches
  },
  data() {
    return {
      title: "Songoro app features management",
      items: [
        {
          text: "Features management",
          href: "/features-management",
          active: true
        },
      ],
      censorExplicitSongs: false,
      voting: true,
      addSong: true,
      boost: true,
      songForYou: true,
      addSongPoolLimit: true,
      addSongLimit: 1,
      boostLimit: 1,
      songForYouLimit: 1,
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Unlimited",
      ]
    };
  },
  methods: {
    confirmPopup() {
      /*Swal.fire({
        title: "Are you sure?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores assumenda delectus deleniti.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4f57a6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });*/
    }
  }
});
